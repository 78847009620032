import { createTheme } from '@mui/material/styles';
import { palette } from '@mui/system';

const theme = createTheme({
    palette: {
        blue: '#385399',
        darkBlue: '#23408E',
        red: '#ED1B24',
        grey: '#CCCCCC',
        white: '#FFFFFF',
        turqoise: '#41e8ab',
        purple: '#4d17a3'
    },
    components: {
        MuiAppBar: {
            color: 'transparent',
            backgroundColor: 'transparent',
            display: 'flex'
        },
        MuiTypography: {
            paddingLeft: 10,
            // fontSize: 24,
            color: '#23408E',
            fonststyle: "bold",
            width: "30vw",
            fontFamily: "Roboto",
            textAlign: "right"
        },
        Query: {
            fontSize: 16,
            color: '#23408E',
            fonststyle: "bold",
            fontFamily: "Roboto",
        },
    },
    others: {
        switchText: {
            cursor: 'pointer',
            color: '#41e8ab',
            fontFamily: "Roboto",
        },
        NavbarLink: {
            cursor: 'pointer',
            userSelect: "None",
            fontSize: 24,
            color: '#FFFFFF',
            fonststyle: "bold",
            fontFamily: "Roboto",
            textAlign: "right"
        },
        Image: {
            objectFit: "cover",
            width: "100%",
            height: "110%"
        },
        imageContainer: {
            height: "100%",
            position: "relative",
            overflow: "hidden",
            marginTop: "5%",
            marginBottom: -10
        },
        paperContainer: {
            width: "100%",
            height: "110%",
            // color: '#23408E'
            background: palette.turqoise,
        },
        textContainer: {
            alignItems: "right",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "absolute",
            top: "30%",
            left: "55%",
            right: "5%"
        }
    }
});

export default theme;