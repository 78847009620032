import React, { useState } from "react";
// import {
//     Typography,
//     MenuItem,
//     Select,
//     Button,
//     Slider,
//     FormControl,
//     InputLabel,
//     OutlinedInput,
//   } from "@mui/material";
  import theme from "../theme";
  import { HelpOutline } from '@mui/icons-material'; 

export default function Modal() {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
        <HelpOutline
        sx={{
            color: theme.palette.turqoise,
            background: theme.palette.pink,
            fontSize: '4rem', 
            minWidth: "10vw",
            cursor: 'pointer',
            border: 'none',  
            padding: 0,      
            margin: 0,     
        }}
        onClick={toggleModal}
      />

    {modal && (
    <div className="modal" style={{ 
        border: theme.palette.turqoise, 
        borderRadius: '10px', 
        padding: '20px', 
        backgroundColor: theme.palette.turqoise,
    }}>
        <div className="modal-content">
        <h2 style={{ fontFamily: 'Times New Roman, serif', color: theme.palette.blue, fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
            How to Submit a Query: 
        </h2>
        <p style={{ fontFamily: 'Times New Roman, serif', color: theme.palette.blue, fontSize: '16px' }}>
            <strong> Select a Query Type </strong>        
            <br />
            <ul style={{ listStyleType: 'circle', marginLeft: '5px' }}>
                <li><strong>Count:</strong> returns number of rows matching the picked conditions </li>
                <li><strong>Min-Max:</strong> returns max/min/average/median value of the chosen condition among the rows matching the other criteria</li>
                <li><strong>Exponential:</strong> returns most frequent of the chosen condition among the rows matching the other criteria</li>
            </ul>
            <strong> Conditions:    0 = no, 1 = yes </strong>   
            <ul style={{ listStyleType: 'circle', marginLeft: '5px' }}>
                <li><strong>CholCheck:</strong> had cholesterol check in the last 5 years?</li>
                <li><strong>Smoker:</strong> Have you smoked at least 100 cigarettes in your entire life? [Note: 5 packs = 100 cigarettes]</li>
                <li><strong>Stroke:</strong> Have you ever had a stroke?</li>
                <li><strong>HeartDiseaseorAttack:</strong> coronary heart disease (CHD) or myocardial infarction (MI)</li>
                <li><strong>PhysActivity:</strong> physical activity in the past 30 days - not including job</li>
                <li><strong>Fruits:</strong> Consume fruit 1 or more times per day</li>
                <li><strong>AnyHealthcare:</strong> Have any kind of health care coverage, including health insurance, prepaid plans such as HMO, etc.</li>
                <li><strong>NoDocbcCost:</strong> Was there a time in the past 12 months when you needed to see a doctor but could not because of cost?</li>
                <li><strong>BMI: </strong> Body Mass Index, not a binary slider, can pick any value between 12 and 98</li>
                <li><strong>Diabetes_012:  </strong> Diabetes 0 is healthy individuals, diabetes 1 prediabetes and diabetes 2 is type 2 diabetes.</li> 
                <li><strong>Min-Max Combo Box: </strong> specifies the type of the query for min-max queries</li>
                <li><strong>Condition Combo Box: </strong>choosing the condition to be search for</li>
            </ul>
        </p>
        </div>
    </div>
    )}
          <p></p>
    </>
  );
}