import React, { useEffect, useState } from "react";
import Box from "@mui/system/Box";
import Navbar from "../Components/Navbar";
import Modal from "./Popup";
// import Button from "@mui/material/Button";
import {
  Typography,
  MenuItem,
  Select,
  Button,
  Slider,
  FormControl,
  InputLabel,
  // OutlinedInput,
} from "@mui/material";
import theme from "../theme";
import CreateSwitch from "../Components/CreateSwitch";
import {
  sendAvg,
  sendCount,
  sendExp,
  sendMin,
  sendMedian,
  sendMax,
} from "../api/api";

const countVars = {
  HighBP: 0,
  HighChol: 0,
  CholCheck: 0,
  Smoker: 0,
  Stroke: 0,
  HeartDiseaseorAttack: 0,
  PhysActivity: 0,
  Fruits: 0,
  AnyHealthcare: 0,
  NoDocbcCost: 0,
};

// const countVars2 = {
//   BMI: 0,
//   PhysHlth: 0,
//   MentHlth: 0,
// };

const operations = ["min", "max", "avg", "median"];

const QueryPage = () => {
  const [selected, setSelected] = useState("");
  const [switchVal, setSwitchVal] = useState(countVars);
  const [target, setTarget] = useState("HighChol");
  const [bmi, setBMI] = useState(55);
  const [diabetes, setDiabetes] = useState(1);
  const [operation, setOperation] = useState("min");
  const [res, setRes] = useState("");

  const handleSwitchChange = (preference, isChecked) => {
    setSwitchVal((prevState) => ({
      ...prevState,
      [preference]: isChecked,
    }));
  };

  const handleSelect = (event) => {
    // console.log(Closing with item: ${event.target.value});
    setSelected(event.target.value);
  };

  const handleTarget = (event) => {
    console.log(target);
    setTarget(event.target.value);
  };

  const handleRender = () => {
    if (!selected) return <></>;

    if (selected === "count") {
      return (
        <Box
          sx={{
            flexDirection: "row",
          }}
        >
          {Object.keys(countVars).map((name, index) => {
            return (
              <CreateSwitch
                key={index}
                handleChange={handleSwitchChange}
                name={name}
              />
            );
          })}
          <Typography style={theme.others.switchText}>Diabetes_012</Typography>
          <Slider
            sx={{
              maxWidth: "10vw",
            }}
            onChange={(event, newValue) => {
              setDiabetes(newValue);
            }}
            valueLabelDisplay="auto"
            value={typeof diabetes === "number" ? diabetes : 0}
            step={1}
            marks
            min={0}
            max={2}
          />
        </Box>
      );
    }

    if (selected === "min_max") {
      return (
        <Box flexDirection={"column"}>
          {Object.keys(countVars).map((name, index) => {
            return (
              <CreateSwitch
                key={index}
                handleChange={handleSwitchChange}
                name={name}
              />
            );
          })}
          <FormControl
            sx={{
              minWidth: "10vw",
              color: theme.palette.purple,
              marginRight: 5,
            }}
          >
            <InputLabel shrink={false}>
              {operation ? "" : "Operation"}
            </InputLabel>
            <Select
              sx={{
                background: theme.palette.turqoise,
                color: theme.palette.white,
              }}
              value={operation}
              onChange={(event) => {
                setOperation(event.target.value);
              }}
            >
              {operations.map((name, index) => {
                return (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              minWidth: "10vw",
              color: theme.palette.purple,
              marginRight: 5,
            }}
          >
            <InputLabel shrink={false}>{target ? "" : "Target"}</InputLabel>
            <Select
              sx={{
                background: theme.palette.turqoise,
                color: theme.palette.white,
              }}
              value={target}
              onChange={handleTarget}
            >
              {Object.keys(countVars).map((name, index) => {
                return (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Typography style={theme.others.switchText}>BMI</Typography>
          <Slider
            sx={{
              maxWidth: "15vw",
            }}
            onChange={(event, newValue) => {
              setBMI(newValue);
            }}
            valueLabelDisplay="auto"
            value={typeof bmi === "number" ? bmi : 0}
            min={12}
            max={98}
          />
          <Typography style={theme.others.switchText}>Diabetes_012</Typography>
          <Slider
            sx={{
              maxWidth: "10vw",
            }}
            onChange={(event, newValue) => {
              setDiabetes(newValue);
            }}
            valueLabelDisplay="auto"
            value={typeof diabetes === "number" ? diabetes : 0}
            step={1}
            marks
            min={0}
            max={2}
          />
        </Box>
      );
    }

    if (selected === "exp") {
      return (
        <Box flexDirection={"column"} alignItems={"center"}>
          {Object.keys(countVars).map((name, index) => {
            return (
              <CreateSwitch
                key={index}
                handleChange={handleSwitchChange}
                name={name}
              />
            );
          })}
          <FormControl
            sx={{
              minWidth: "10vw",
              color: theme.palette.purple,
              marginRight: 5,
            }}
          >
            <InputLabel shrink={false}>{target ? "" : "Target"}</InputLabel>
            <Select
              sx={{
                background: theme.palette.turqoise,
                color: theme.palette.white,
              }}
              value={target}
              onChange={handleTarget}
            >
              {Object.keys(countVars).map((name, index) => {
                return (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Typography style={theme.others.switchText}>BMI</Typography>
          <Slider
            sx={{
              maxWidth: "15vw",
            }}
            onChange={(event, newValue) => {
              setBMI(newValue);
            }}
            valueLabelDisplay="auto"
            value={typeof bmi === "number" ? bmi : 0}
            min={12}
            max={98}
          />
          <Typography style={theme.others.switchText}>Diabetes_012</Typography>
          <Slider
            sx={{
              maxWidth: "10vw",
            }}
            onChange={(event, newValue) => {
              setDiabetes(newValue);
            }}
            valueLabelDisplay="auto"
            value={typeof diabetes === "number" ? diabetes : 0}
            step={1}
            marks
            min={0}
            max={2}
          />
        </Box>
      );
    }

    return <></>;
  };

  useEffect(() => {
    setSwitchVal((prevState) => ({
      ...prevState,
      Diabetes_012: diabetes,
    }));
  }, [switchVal, diabetes]);

  const handleSubmit = () => {
    const sendData = {
      condition_dict: switchVal,
      target_attribute: target,
    };

    if (selected === "count") {
      sendCount(switchVal).then((response) => setRes(response.data.noisyVal));
    }

    if (selected === "min_max") {
      if (operation === "min") {
        sendMin(sendData).then((response) => {
          if (response.data.noisyVal === -1) {
            setRes("Could not find any input matching the given conditions");
          } else {
            setRes(response.data.noisyVal);
          }
        });
      }
      if (operation === "max") {
        sendMax(sendData).then((response) => {
          if (response.data.noisyVal === -1) {
            setRes("Could not find any input matching the given conditions");
          } else {
            setRes(response.data.noisyVal);
          }
        });
      }
      if (operation === "avg") {
        sendAvg(sendData).then((response) => {
          if (response.data.noisyVal === -1) {
            setRes("Could not find any input matching the given conditions");
          } else {
            setRes(response.data.noisyVal);
          }
        });
      }
      if (operation === "median") {
        sendMedian(sendData).then((response) => {
          if (response.data.noisyVal === -1) {
            setRes("Could not find any input matching the given conditions");
          } else {
            setRes(response.data.noisyVal);
          }
        });
      }
    }

    if (selected === "exp") {
      sendExp(sendData).then((response) => {
        console.log("hereee" + response.data.noisyVal)
        if (response.data.noisyVal === -1) {
          setRes("Could not find any input matching the given conditions");
        } else {
          setRes(response.data.noisyVal);
        }
      });
    }

    console.log(switchVal);
  };

  return (
    <>
      <Box>
        <Navbar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "150vh",
            paddingTop: "20vh",
            paddingLeft: "10vw",
            background: theme.palette.darkBlue,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxHeight: "10vh",
              maxWidth: "80vw",
            }}
          >
            <FormControl
              sx={{
                marginBottom: "2vh",
              }}
            >
              <InputLabel shring="false">
                {selected ? "" : "Select Query Type"}
              </InputLabel>
              <Select
                sx={{
                  background: theme.palette.turqoise,
                  color: theme.palette.white,
                  minWidth: "10vw",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selected}
                label="Select Query Type"
                onChange={handleSelect}
              >
                <MenuItem value={"count"}>Count</MenuItem>
                <MenuItem value={"min_max"}>Min-Max</MenuItem>
                <MenuItem value={"exp"}>Exponential</MenuItem>
              </Select>
            </FormControl>

            {handleRender()}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "24px",
              }}
            >
              <Button
                sx={{
                  color: theme.palette.white,
                  background: theme.palette.turqoise,
                  minWidth: "10vw",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
            <Typography
              variant="body1"
              style={{ color: "pink", fontSize: "36px", fontStyle: "italic" }}
            >
              {res ? res : ""}
            </Typography>
            <>
              <Modal />
            </>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default QueryPage;
