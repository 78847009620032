import React from 'react';

import { Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage'
import QueryPage from './Pages/QueryPage';
import Results from './Pages/Results';

function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/results' element={<Results />} />
      <Route path='/queries' element={<QueryPage />} />
    </Routes>
  );
}

export default App;
