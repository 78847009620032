import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import Navbar from "../Components/Navbar";
// import backgroundImage from "./home_page_image.avif";
// import backgroundImage2 from "./homepageimage.jpg";

import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
import HumanAnimation from "./HumanAnimation";
// import FragShader from "./FragShader";

const HomePage = () => {

    const text =
        "Welcome to PrivateHealth,"
    const text1 = "where you can confidently explore health data through various queries, assured that the privacy of your information is safeguarded by robust Differential Privacy mechanisms, resilient against adversarial threats.";
    const text2 = " to begin collecting the information you need, all under the protection of differential privacy. Alternatively, visit the Results page to explore experimental outcomes of the Differential Privacy algorithm."
    return (
        <ThemeProvider theme={theme}>
            <Paper sx={{
                background: theme.palette.turqoise,
                width: "100vw",
                height: "100vh",
            }}>
                {/* <Box style={theme.others.paperContainer}
                    sx={{
                        background: theme.palette.darkBlue
                    }}
                > */}
                <HumanAnimation />
                <Navbar />
                {/* <FragShader /> */}

                <Box style={theme.others.textContainer}
                    sx={{
                        background: theme.palette.turqoise,
                        height: '40vh',
                        flexDirection: "column",
                        justifyContent: "space-between"

                    }}
                >
                    <Box>
                        <Typography sx={{
                            fontSize: '2vw',
                            color: '#23408E',
                            fonststyle: "bold",
                            width: "20vw",
                            fontFamily: "Roboto",
                        }}>
                            {text}
                        </Typography>
                        <Typography style={theme.components.MuiTypography} sx={{
                            fontSize: '1vw',
                        }} >
                            {text1}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography sx={{
                            fontSize: '2vw',
                            color: '#23408E',
                            fonststyle: "bold",
                            width: "20vw",
                            fontFamily: "Roboto",
                        }}>
                            Navigate to the Queries page,
                        </Typography>
                        <Typography style={theme.components.MuiTypography} sx={{
                            fontSize: '1vw',
                        }}>
                            {text2}
                        </Typography>
                    </Box>

                </Box>
                {/* </Box> */}
            </Paper>
        </ThemeProvider>
    );
};

export default HomePage;
