import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF, Stage } from "@react-three/drei";
import theme from "../theme";
import { MeshStandardMaterial} from "three";

function Model(props) {
    const groupRef = useRef();
    const gltf = useGLTF("/humanbody.glb");
    const [lastLogTime, setLastLogTime] = useState(0);
    const [color, setColor] = useState('rgba(50, 0, 168, 1)');
    const [x, SetX] = useState(0);
    const [increase, setInc] = useState(false);

    useFrame(({ clock }) => {
        const elapsedTime = clock.getElapsedTime();
        const rotationY = (elapsedTime % 5) / 5;

        gltf.scene.traverse((child) => {
            if (child.isMesh || lastLogTime / 5 === 3) {
                child.material = new MeshStandardMaterial({ color: color });
            }
        });

        if (elapsedTime - lastLogTime >= 0.05) {
            if (x >= 255) {
                setInc(false);
                SetX(255);
            } else if (x <= 5) {
                setInc(true);
                SetX(0);
            }

            setLastLogTime(elapsedTime);

            if (increase) {
                SetX(x + 2);
            } else {
                SetX(x - 2);
            }

            const colorString = 'rgba(50,' + x + ', 168, 1)';
            // console.log(colorString)

            setColor(colorString)
        }

        if (groupRef.current) {
            groupRef.current.rotation.y = rotationY * Math.PI * 2;
        }
    });

    return (
        <group ref={groupRef} position={props.position || [0, 0, 0]} {...props}>
            <primitive object={gltf.scene} />
        </group>
    );
}


function HumanAnimation() {

    return (
        <Canvas style={{ width: "40vw", height: "100vh" }} camera={{ position: [0, 0, -2], fov: 80, aspect: 4, near: 1, far: 10 }} >
            <color attach="background" args={[theme.palette.turqoise]} />
            <Stage environment={"forest"}>
                <Model scale={1} />
            </Stage>
        </Canvas>
    );
};

export default HumanAnimation;
