import React from "react";
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
import image from "./privatehealth.png";
const Navbar = () => {
    const navigator = useNavigate();

    const handleClick = () => {
        navigator("/results");
    };

    const handleQueries = () => {
        navigator("/queries");
    };


    return (
        <ThemeProvider theme={theme}>
            <AppBar style={theme.components.MuiAppBar} sx={{
                backgroundColor: theme.palette.darkBlue
            }}>
                <Toolbar style={theme.components.MuiToolbar}>
                    <Box sx={{ flexGrow: 0.4 }}>
                        <a href="/">
                            <img src={image} alt="ConceptLogosx" style={{
                                maxWidth: 200,
                                paddingRight: 80,
                            }} />
                        </a>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        // flexGrow: 1,
                        width: '40vw',
                        justifyContent: 'space-between'
                    }}>
                        <Box onClick={handleQueries}
                        >
                            <Typography style={theme.others.NavbarLink}>
                                Queries
                            </Typography>
                        </Box>
                        <Box onClick={handleClick}>
                            <Typography style={theme.others.NavbarLink}>
                                Results
                            </Typography>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar >
        </ThemeProvider>
    )
}

export default Navbar;