import { useState } from "react";
import {
    Switch, Typography,
    FormControl, FormLabel, Stack
} from "@mui/material";
import theme from "../theme";



const CreateSwitch = ({ name, handleChange }) => {
    const [on, setOn] = useState(false);
    const handleToggle = () => {
        const newVal = !on;
        setOn(newVal);
        const val = newVal ? 1 : 0;
        handleChange(name, val)
    }

    return (
        <FormControl component="fieldset" sx={{ marginRight: 10 }}>
            <FormLabel style={theme.others.switchText} component="legend">{name}</FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography style={theme.others.switchText}>0</Typography>
                <Switch sx={{ color: theme.palette.turqoise }}
                    checked={on}
                    onChange={handleToggle}
                />
                <Typography style={theme.others.switchText}>1</Typography>
            </Stack>
        </FormControl>
    );
};

export default CreateSwitch;