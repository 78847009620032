import axios from 'axios';

const url = 'http://127.0.0.1:8000';

export const sendCount = (userData) => {
    // eslint-disable-next-line no-console
    const count = axios.post(`${url}/count/`, userData);

    return count;
};

export const sendMax = (userData) => {
    // eslint-disable-next-line no-console
    const count = axios.post(`${url}/maxInt/`, userData);

    return count;
};
export const sendMin = (userData) => {
    // eslint-disable-next-line no-console
    const count = axios.post(`${url}/minInt/`, userData);

    return count;
};
export const sendAvg = (userData) => {
    // eslint-disable-next-line no-console
    const count = axios.post(`${url}/avgInt/`, userData);

    return count;
};
export const sendMedian = (userData) => {
    // eslint-disable-next-line no-console
    const count = axios.post(`${url}/medianInt/`, userData);

    return count;
};

export const sendExp = (userData) => {
    // eslint-disable-next-line no-console
    const count = axios.post(`${url}/exponential/`, userData);
    console.log("here" + count)

    return count;
};