import * as React from "react";
import Box from "@mui/system/Box";
import Navbar from "../Components/Navbar";
import theme from "../theme";
import { PieChart } from "@mui/x-charts/PieChart";
// import { BarChart } from "@mui/x-charts/BarChart";
import { Typography } from "@mui/material";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const Results = () => {
  // Sample data for the pie charts (replace it with your actual data)

  const data01 = [
    { name: "0.0001", value: 214666.13 },
    { name: "0.001", value: 213504.05 },
    { name: "0.01", value: 213692.99 },
    { name: "0.1", value: 213700.78 },
    { name: "1.0", value: 213703.06 },
  ];
  const data02 = [
    { name: "0.0001", value: 4.734070181513596 },
    { name: "0.001", value: 0.45142557661801663 },
    { name: "0.01", value: 0.037430452543951186 },
    { name: "0.1", value: 0.004482857049269313 },
    { name: "1.0", value: 0.0005053742811284821 },
  ];
  const data11 = [
    { name: "0.0001", value: 5518.56 },
    { name: "0.001", value: 4638.84 },
    { name: "0.01", value: 4633.74 },
    { name: "0.1", value: 4629.08 },
    { name: "1.0", value: 4631.18 },
  ];
  const data12 = [
    { name: "0.0001", value: 200.955 },
    { name: "0.001", value: 20.764 },
    { name: "0.01", value: 1.891 },
    { name: "0.1", value: 0.237 },
    { name: "1.0", value: 0.025 },
  ];
  const data21 = [
    { name: "0.0001", value: 34762.54 },
    { name: "0.001", value: 35225.09 },
    { name: "0.01", value: 35337.49 },
    { name: "0.1", value: 35345.49 },
    { name: "1.0", value: 35346.02 },
  ];
  const data22 = [
    { name: "0.0001", value: 27.776 },
    { name: "0.001", value: 3.128 },
    { name: "0.01", value: 0.25 },
    { name: "0.1", value: 0.026 },
    { name: "1.0", value: 0.002 },
  ];

  return (
    <>
      <Box>
        <Navbar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Set flexDirection to column
            alignItems: "center", // Align items to the center
            height: "350vh",
            paddingTop: "20vh",
            paddingLeft: "10vw",
            background: theme.palette.darkBlue,
          }}
        >
          <Typography
            sx={{
              marginBottom: 5,
              fontWeight: "bold",
              marginRight: 10,
              fontSize: 25,
            }}
          >
            Error Performance of Laplace Mechanism
          </Typography>
          <Box sx={{ display: "flex", marginRight: 10, marginBottom: 3 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              Number of patients with Diabetes_0:
            </Typography>
            <LineChart width={400} height={300} data={data01}>
              <XAxis dataKey="name" />
              <YAxis domain={[212500, 215000]} />{" "}
              {/* Adjust the domain for the desired y-axis range */}
              <CartesianGrid stroke="#eee" />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#41e8ab"
                strokeWidth={3}
              />
              <Tooltip />
              <Legend />
            </LineChart>

            <Typography
              sx={{ fontWeight: "bold", marginLeft: 10, marginRight: -3 }}
            >
              Error percentage of Diabetes_0 Patients:
            </Typography>
            <LineChart width={400} height={300} data={data02}>
              <XAxis dataKey="name" />
              <YAxis domain={[0, 5]} />{" "}
              {/* Adjust the domain for the desired y-axis range */}
              <CartesianGrid stroke="#eee" />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#41e8ab"
                strokeWidth={3}
              />
              <Tooltip />
              <Legend />
            </LineChart>
          </Box>
          <Box sx={{ display: "flex", marginRight: 10, marginBottom: 3 }}>
            <Typography sx={{ fontWeight: "bold" }}>
            Number of patients with Diabetes_1:
            </Typography>
            <LineChart width={400} height={300} data={data11}>
              <XAxis dataKey="name" />
              <YAxis domain={[4000, 6000]} />{" "}
              {/* Adjust the domain for the desired y-axis range */}
              <CartesianGrid stroke="#eee" />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#41e8ab"
                strokeWidth={3}
              />
              <Tooltip />
              <Legend />
            </LineChart>
            <Typography
              sx={{ fontWeight: "bold", marginLeft: 10, marginRight: -3 }}
            >
              Error percentage of Diabetes_1 Patients:
            </Typography>
            <LineChart width={400} height={300} data={data12}>
              <XAxis dataKey="name" />
              <YAxis domain={[0, 250]} />{" "}
              {/* Adjust the domain for the desired y-axis range */}
              <CartesianGrid stroke="#eee" />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#41e8ab"
                strokeWidth={3}
              />
              <Tooltip />
              <Legend />
            </LineChart>
          </Box>
          <Box sx={{ display: "flex", marginRight: 10 }}>
            <Typography sx={{ fontWeight: "bold" }}>
            Number of patients with Diabetes_2:
            </Typography>
            <LineChart width={400} height={300} data={data21}>
              <XAxis dataKey="name" />
              <YAxis domain={[32000, 40000]} />{" "}
              {/* Adjust the domain for the desired y-axis range */}
              <CartesianGrid stroke="#eee" />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#41e8ab"
                strokeWidth={3}
              />
              <Tooltip />
              <Legend />
            </LineChart>
            <Typography
              sx={{ fontWeight: "bold", marginLeft: 10, marginRight: -3 }}
            >
              Error percentage of Diabetes_2 Patients:
            </Typography>
            <LineChart width={400} height={300} data={data22}>
              <XAxis dataKey="name" />
              <YAxis domain={[0, 40]} />{" "}
              {/* Adjust the domain for the desired y-axis range */}
              <CartesianGrid stroke="#eee" />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#41e8ab"
                strokeWidth={3}
              />
              <Tooltip />
              <Legend />
            </LineChart>
          </Box>
          <Typography
            sx={{
              marginBottom: 5,
              fontWeight: "bold",
              marginRight: 10,
              fontSize: 25,
              marginTop: 10,
            }}
          >
            Error Performance of Exponential Mechanism
          </Typography>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>
              Epsilon = 0.0001:
            </Typography>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 709, label: "age group 1" },
                    { id: 1, value: 661, label: "age group 2" },
                    { id: 2, value: 706, label: "age group 3" },
                    { id: 3, value: 683, label: "age group 4" },
                    { id: 4, value: 668, label: "age group 5" },
                    { id: 5, value: 712, label: "age group 6" },
                    { id: 6, value: 732, label: "age group 7" },
                    { id: 7, value: 844, label: "age group 8" },
                    { id: 8, value: 896, label: "age group 9" },
                    { id: 9, value: 912, label: "age group 10" },
                    { id: 10, value: 891, label: "age group 11" },
                    { id: 11, value: 789, label: "age group 12" },
                    { id: 12, value: 797, label: "age group 13" },
                  ],
                },
              ]}
              width={800}
              height={300}
              colors={[
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
                "#9966FF",
                "#FF8C00",
                "#2E8B57",
                "#8A2BE2",
                "#FFD700",
                "#008080",
                "#FF6347",
                "#808000",
                "#9400D3",
              ]}
            />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>
              Epsilon = 0.001:
            </Typography>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 105, label: "age group 1" },
                    { id: 1, value: 142, label: "age group 2" },
                    { id: 2, value: 124, label: "age group 3" },
                    { id: 3, value: 142, label: "age group 4" },
                    { id: 4, value: 206, label: "age group 5" },
                    { id: 5, value: 235, label: "age group 6" },
                    { id: 6, value: 529, label: "age group 7" },
                    { id: 7, value: 915, label: "age group 8" },
                    { id: 8, value: 1985, label: "age group 9" },
                    { id: 9, value: 2969, label: "age group 10" },
                    { id: 10, value: 1487, label: "age group 11" },
                    { id: 11, value: 586, label: "age group 12" },
                    { id: 12, value: 575, label: "age group 13" },
                  ],
                },
              ]}
              width={800}
              height={300}
              colors={[
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
                "#9966FF",
                "#FF8C00",
                "#2E8B57",
                "#8A2BE2",
                "#FFD700",
                "#008080",
                "#FF6347",
                "#808000",
                "#9400D3",
              ]}
            />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>Epsilon = 0.01:</Typography>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 0, label: "age group 1" },
                    { id: 1, value: 0, label: "age group 2" },
                    { id: 2, value: 0, label: "age group 3" },
                    { id: 3, value: 0, label: "age group 4" },
                    { id: 4, value: 0, label: "age group 5" },
                    { id: 5, value: 0, label: "age group 6" },
                    { id: 6, value: 0, label: "age group 7" },
                    { id: 7, value: 0, label: "age group 8" },
                    { id: 8, value: 140, label: "age group 9" },
                    { id: 9, value: 9848, label: "age group 10" },
                    { id: 10, value: 12, label: "age group 11" },
                    { id: 11, value: 0, label: "age group 12" },
                    { id: 12, value: 0, label: "age group 13" },
                  ],
                },
              ]}
              width={800}
              height={300}
              colors={[
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
                "#9966FF",
                "#FF8C00",
                "#2E8B57",
                "#8A2BE2",
                "#FFD700",
                "#008080",
                "#FF6347",
                "#808000",
                "#9400D3",
              ]}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Results;
